/* .App {
    text-align: center;
  } */

.App-logo {
    height: 20vmin;
    pointer-events: none;
    animation: App-logo-spin infinite 5s linear;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
